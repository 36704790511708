import { defineStore } from "pinia";
import type {
  IPaginatedResponse,
  IResponseLinks,
  IResponseMeta
} from "@/models/common";
import type {
  EmailCustomizationViewParams,
  EmailTemplate
} from "@/models/clients";
import customizationApiService from "@/services/modules/customization";

interface UseCustomizationState {
  emailTemplates: IPaginatedResponse<EmailTemplate>;
  activeEmailTemplate: EmailTemplate | null;
}

const getDefaultState = (): UseCustomizationState => ({
  emailTemplates: {
    data: [],
    meta: {} as IResponseMeta,
    links: {} as IResponseLinks
  },
  activeEmailTemplate: null
});

const useCustomizationStore = defineStore("customization", {
  state: getDefaultState,
  actions: {
    async getEmailTemplates(payload: EmailCustomizationViewParams) {
      const dataPayload: EmailCustomizationViewParams = {
        page: payload.page ?? 1,
        per_page: payload.per_page ?? 100
      };

      if (payload.search) {
        dataPayload.search = payload.search;
      }
      const data = await customizationApiService.getEmailTemplates(dataPayload);
      this.emailTemplates = data;
      return data;
    },
    async getEmailTemplate(id: string) {
      const data = await customizationApiService.getEmailTemplate(id);
      this.activeEmailTemplate = data;
    },
    async updateEmailTemplate(payload: {
      id: string;
      data: Partial<EmailTemplate>;
    }) {
      const data = await customizationApiService.updateEmailTemplate(payload);
      if (data) {
        this.activeEmailTemplate = data;
      }
    },
    async createEmailTemplate(payload: Partial<EmailTemplate>) {
      const data = await customizationApiService.createEmailTemplate(payload);
      this.activeEmailTemplate = data;
    },
    unsetEmailTemplates() {
      this.emailTemplates = getDefaultState().emailTemplates;
    }
  }
});

export default useCustomizationStore;
